.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  line-height: 2;
  box-sizing: content-box;
}

.Main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.Homepage {
  text-align: center;
  display: grid;
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: center; */
}

.Profile-link {
  color: #61dafb;
}

.Calc-page {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  background-color: #121212;
  color: #fafafa;

  background-image: url('./mona.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 80% center;
}

.App-horiz-layout {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 5%;
  white-space: nowrap;
}

.App-horiz-layout-unspaced {
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  white-space: nowrap;
  margin-top: 0.25em;
  margin-left: max(10vw, 10%);
}

.App-horiz-layout-disabled {
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  white-space: nowrap;
  margin-top: 0.25em;
  margin-left: max(10vw, 10%);
  /* font-size: calc(8px + 1vmin); */
  font-size: calc(10px + 2vmin);
  font-style: italic;
}

.Calc-horiz-layout-left {
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  display: flex;
  flex-grow: 1;
  width: 100vh;
}

.Calc-horiz-layout-right {
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  display: flex;
  flex-grow: 3;
}

.App-language-layout {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 5%;
  white-space: nowrap;
  margin-bottom: 0;
}

.Text-input {
  font-size: calc(10px + 2vmin);
  width: calc(20px + 10vw);
  margin-left: calc(1vmin);
}

.Text-input-disabled {
  /* font-size: calc(8px + 1vmin); */
  font-size: calc(10px + 2vmin);
  font-style: italic;
  width: calc(50px + 10vw);
  margin-left: calc(1vmin);
}

.Submit-button {
  font-size: calc(10px + 2vmin);
  background-color: #4caf50;
  padding: 2vh 3vw;
  color: #fafafa;
  border-radius: 2vh;
}

.Submit-button:hover {
  /* color: black; */
  color: #fafafa;
  background-color: #3b873f;
  /* border: 2px solid azure; */
}
